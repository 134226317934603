import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import Evaluate from 'components/evaluate/Evaluate';

export default function Landing() {
    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar />
            </div>
            <main>
                <Evaluate />
            </main>
            <DefaultFooter />
        </>
    );
}
