export default function DefaultFooter() {
  return (
    <>
      <footer className="relative pt-8 pb-6">
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-700 font-medium py-1">
                สำนักงานกองทุนสนับสนุนการสร้างเสริมสุขภาพ (สสส.)
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
