import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import H5 from "@material-tailwind/react/Heading5";
import Radio from "@material-tailwind/react/radio";
import Label from "@material-tailwind/react/Label";
import Paragraph from "@material-tailwind/react/Paragraph";
import Input from "@material-tailwind/react/Input";
import Tab from "@material-tailwind/react/Tab";
import TabList from "@material-tailwind/react/TabList";
import TabItem from "@material-tailwind/react/TabItem";
import TabContent from "@material-tailwind/react/TabContent";
import TabPane from "@material-tailwind/react/TabPane";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";
import SweetAlert from "react-bootstrap-sweetalert";
import { TitleService } from "../../providers";

export default function Evaluate() {
  const [titles, setTitle] = useState([]);
  const [openTab, setOpenTab] = useState(1);
  const [alert, setAlert] = useState({ alert: null });
  const [answerno, setAnswerNo] = useState({ answerno: null });
  const history = useHistory();

  useEffect(() => {
    const username = localStorage.getItem("user");
    const answerno = localStorage.getItem("answerno") === null ? '' : localStorage.getItem("answerno");
    setAnswerNo({ answerno: answerno });
    TitleService.fetchTitle(username, answerno).then((res) => {
      setTitle(res);
    });
  }, []);

  const handleChange = (item1, item2, type) => (event) => {
    let arrItem = [];
    for (let a of titles) {
      if (a.groupid === item1.groupid) {
        for (let b of a.title) {
          if (b.titleid === item2.titleid) {
            for (let c of b.subtitle) {
              if (c.subtitleid === item2.subtitleid) {
                c.isChecked = 1;
                c.other = type === "input" ? event.target.value : "";
              } else c.isChecked = 0;
            }
          }
        }
      }
      arrItem.push(a);
    }
    setTitle(arrItem);
  };

  const clickSubmit = (event) => {
    let arrAnswer = {};
    arrAnswer.username = localStorage.getItem('user');
    arrAnswer.titles = titles;
    event.preventDefault();
    TitleService.insertAnswer(arrAnswer).then((data) => {
      if (data.error) {
        onError(data.error);
      } else {
        onSuccess();
      }
    });
  };

  const onSuccess = () => {
    setAlert({
      alert: (
        <SweetAlert
          success
          title="ดําเนินการเรียบร้อยแล้ว"
          onConfirm={() => hideAlert("success")}
        >
          ข้อมูลของคุณได้ทำการบันทึกเรียบร้อยแล้ว
        </SweetAlert>
      ),
    });
  };

  const onError = (err) => {
    setAlert({
      alert: (
        <SweetAlert title="" onConfirm={() => hideAlert("error")}>
          {err}
        </SweetAlert>
      ),
    });
  };

  const hideAlert = (str) => {
    setAlert({ alert: null });
    localStorage.removeItem('answerno');
    if (str === "success") history.push("/");
  };

  return (
    <section className="pt-20 pb-1 bg-gray-100">
      <div className="container max-w-7xl mx-auto px-4">
        <div className="pt-6 pb-6 text-center">
          <H5>แบบประเมินความรู้ ความเข้าใจ EF ของพ่อแม่/ผู้ปกครอง</H5>
        </div>
        {alert.alert}
        <Tab>
          <TabList color="green">
            {titles.map((item, index) => (
              <TabItem
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(item.groupid);
                }}
                ripple="light"
                active={openTab === item.groupid ? true : false}
                href="tabItem"
              >
                {item.groupname}
              </TabItem>
            ))}
          </TabList>

          <TabContent>
            {titles.map((item, index, row) => (
              <div key={index}>
                <TabPane active={openTab === item.groupid ? true : false}>
                  {item.title.map((item1, index1) => (
                    <div key={index1} className="text-left">
                      {item1.isheader !== 0 ? (
                        <Label color="lightBlue">{item1.titlename}</Label>
                      ) : (
                        <Label color="gray">
                          {item1.numberid + "."} {item1.titlename}
                        </Label>
                      )}

                      <div className="pt-2 px-2">
                        <Paragraph>
                          {item1.subtitle.map((item2, index2) => (
                            <div key={index2}>
                              <Radio
                                color="lightBlue"
                                size="sm"
                                text={item2.subtitlename}
                                id={"option" + item2.titleid + item2.subtitleid}
                                name={"option" + item2.titleid}
                                onChange={handleChange(item1, item2, "redio")}
                                checked={item2.isChecked === 1}
                                disabled={answerno.answerno.length > 0}
                              />
                              {item2.typevalue === 1 ? (
                                <div className="px-6">
                                  <Input
                                    type="text"
                                    color="lightBlue"
                                    size="sm"
                                    outline={true}
                                    placeholder=""
                                    value={item2.other}
                                    disabled={answerno.answerno.length > 0}
                                    onChange={handleChange(
                                      item1,
                                      item2,
                                      "input"
                                    )}
                                  />
                                </div>
                              ) : null}
                            </div>
                          ))}
                        </Paragraph>
                      </div>
                    </div>
                  ))}
                </TabPane>
                <div className="flex">
                  {openTab > 1 && openTab === item.groupid ? (
                    <div>
                      <Button
                        color="blueGray"
                        buttonType="filled"
                        size="regular"
                        rounded={false}
                        block={false}
                        iconOnly={false}
                        ripple="light"
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(index + 1 - 1);
                        }}
                      >
                        <Icon name="arrow_back" size="sm" /> ย้อนกลับ
                      </Button>
                    </div>
                  ) : null}

                  {(openTab === row.length && openTab === item.groupid && answerno.answerno.length === 0) ? (
                    <div className="floatright">
                      <Button
                        color="green"
                        buttonType="filled"
                        size="regular"
                        rounded={false}
                        block={false}
                        iconOnly={false}
                        ripple="light"
                        onClick={clickSubmit}
                      >
                        <Icon name="save" size="sm" /> บันทึก
                      </Button>
                    </div>
                  ) : null}
                  {openTab === item.groupid && openTab !== row.length ? (
                    <div className="floatright">
                      <Button
                        color="green"
                        buttonType="filled"
                        size="regular"
                        rounded={false}
                        block={false}
                        iconOnly={false}
                        ripple="light"
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(index + 2);
                        }}
                      >
                        ถัดไป <Icon name="arrow_forward" size="sm" />
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
          </TabContent>
        </Tab>
      </div>
    </section>
  );
}
