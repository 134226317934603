import React, { useState, useEffect } from "react";
import Input from "@material-tailwind/react/Input";
import Tab from "@material-tailwind/react/Tab";
import TabList from "@material-tailwind/react/TabList";
import TabItem from "@material-tailwind/react/TabItem";
import TabContent from "@material-tailwind/react/TabContent";
import TabPane from "@material-tailwind/react/TabPane";
import Button from "@material-tailwind/react/Button";
import Icon from "@material-tailwind/react/Icon";
import SweetAlert from "react-bootstrap-sweetalert";
import { ProvinceService, UserService } from "../../providers";
import Select from "react-select";
import { isEmpty } from "../../utils/utilities";

export default function Content() {
  const [openTab, setOpenTab] = useState(1);
  const [provinces, setProvince] = useState([]);
  const [alert, setAlert] = useState({ alert: null });
  const [values, setValues] = useState({
    username: "",
    password: "",
    confirmpassword: "",
    firstname: "",
    lastname: "",
    email: "",
    provinceid: 0,
    error: "",
    success: false,
  });

  useEffect(() => {
    async function fetchMyAPI() {
      await ProvinceService.fetchProvince().then((res) => {
        setProvince(res);
      });
  
      await UserService.fetchUserByID(localStorage.getItem("user")).then((res) => {
        setValues({ 
          username: res.username,
          firstname: res.firstname,
          lastname: res.lastname,
          email: res.email,
          provinceid: res.provinceid
        });
      });
    }

    fetchMyAPI()
  }, []);

  const handleChange = (name) => (event) => {
    if (name === "provinceid")
      setValues({ ...values, error: false, [name]: event.value });
    else setValues({ ...values, error: false, [name]: event.target.value });
  };

  const handleError = (name, fieldname) => {
    if (
      (name === "username" && isEmpty(values.username)) ||
      (name === "password" && isEmpty(values.password)) ||
      (name === "confirmpassword" && isEmpty(values.confirmpassword)) ||
      (name === "firstname" && isEmpty(values.firstname)) ||
      (name === "lastname" && isEmpty(values.lastname))
    )
      return `จำเป็นต้องกรอก ${fieldname}`;
    if (
      name === "confirmpassword" &&
      values.password !== values.confirmpassword
    )
      return `รหัสผ่านกับยืนยันรหัสผ่านไม่ตรงกัน`;
  };

  const clickSubmitInformation = (event) => {
    if (
      !isEmpty(values.username) &&
      !isEmpty(values.firstname) &&
      !isEmpty(values.lastname)
    ) {
      event.preventDefault();
      setValues({ ...values, error: false });
      UserService.updateUser(values).then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error });
          onError(data.error);
        } else {
          onSuccess();
        }
      });
    }
  };

  const clickSubmitChangePassword = (event) => {
    if (!isEmpty(values.password) && !isEmpty(values.confirmpassword)) {
      event.preventDefault();
      setValues({ ...values, error: false });
      UserService.changePassword(values).then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error });
          onError(data.error);
        } else {
          onSuccess();
        }
      });
    }
  };

  const onSuccess = () => {
    setAlert({
      alert: (
        <SweetAlert
          success
          title="ดําเนินการเรียบร้อยแล้ว"
          onConfirm={() => hideAlert("success")}
        >
          ข้อมูลของคุณได้ทำการบันทึกเรียบร้อยแล้ว
        </SweetAlert>
      ),
    });
  };

  const onError = (err) => {
    setAlert({
      alert: (
        <SweetAlert title="" onConfirm={() => hideAlert("error")}>
          {err}
        </SweetAlert>
      ),
    });
  };

  const hideAlert = (str) => {
    setAlert({ alert: null });
  };

  return (
    <section className="pt-20 pb-1 bg-gray-100">
      <div className="container max-w-7xl mx-auto px-4">
        {alert.alert}
        <Tab>
          <TabList color="green">
            <TabItem
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(1);
              }}
              ripple="light"
              active={openTab === 1 ? true : false}
              href="tabItem"
            >
              แก้ไขข้อมูลส่วนตัว
            </TabItem>
            <TabItem
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
              }}
              ripple="light"
              active={openTab === 2 ? true : false}
              href="tabItem"
            >
              เปลี่ยนรหัสผ่าน
            </TabItem>
          </TabList>

          <TabContent>
            <div>
              <TabPane active={openTab === 1 ? true : false}>
                <div className="text-left">
                  <div className="flex flex-wrap mt-2">
                    <div className="w-full lg:w-12/12 mb-5 font-light">
                      <Input
                        type="text"
                        color="lightBlue"
                        placeholder="ชื่อผู้ใช้งาน"
                        value={values.username}
                        error={handleError("username", "ชื่อผู้ใช้งาน")}
                        onChange={handleChange("username")}
                        disabled={true}
                      />
                    </div>
                    <div className="w-full lg:w-12/12 mb-5 font-light">
                      <Input
                        type="text"
                        color="lightBlue"
                        placeholder="ชื่อ"
                        value={values.firstname}
                        error={handleError("firstname", "ชื่อ")}
                        onChange={handleChange("firstname")}
                      />
                    </div>
                    <div className="w-full lg:w-12/12 mb-5 font-light">
                      <Input
                        type="text"
                        color="lightBlue"
                        placeholder="นามสกุล"
                        value={values.lastname}
                        error={handleError("lastname", "นามสกุล")}
                        onChange={handleChange("lastname")}
                      />
                    </div>
                    <div className="w-full lg:w-12/12 mb-5 font-light">
                      <Input
                        type="email"
                        color="lightBlue"
                        placeholder="อีเมลล์"
                        value={values.email}
                        onChange={handleChange("email")}
                      />
                    </div>
                    <div className="w-full lg:w-12/12 mb-5 font-light">
                      <Select
                        options={provinces}
                        placeholder="-- กรุณาเลือกจังหวัด --"
                        value = {
                          provinces.filter(option => 
                             option.value === values.provinceid)
                        }
                        onChange={handleChange("provinceid")}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <Button
                      color="green"
                      buttonType="filled"
                      size="regular"
                      rounded={false}
                      block={false}
                      iconOnly={false}
                      ripple="light"
                      onClick={clickSubmitInformation}
                    >
                      <Icon name="save" size="sm" /> บันทึก
                    </Button>
                  </div>
                </div>
              </TabPane>
              <TabPane active={openTab === 2 ? true : false}>
                <div className="text-left">
                  <div className="flex flex-wrap mt-2">
                    <div className="w-full lg:w-12/12 mb-5 font-light">
                      <Input
                        type="password"
                        color="lightBlue"
                        placeholder="รหัสผ่านใหม่"
                        error={handleError("password", "รหัสผ่านใหม่")}
                        onChange={handleChange("password")}
                      />
                    </div>
                    <div className="w-full lg:w-12/12 mb-5 font-light">
                      <Input
                        type="password"
                        color="lightBlue"
                        placeholder="ยืนยันรหัสผ่านใหม่"
                        error={handleError(
                          "confirmpassword",
                          "ยืนยันรหัสผ่านใหม่"
                        )}
                        onChange={handleChange("confirmpassword")}
                      />
                    </div>
                  </div>
                  <div className="flex justify-center">
                    <Button
                      color="green"
                      buttonType="filled"
                      size="regular"
                      rounded={false}
                      block={false}
                      iconOnly={false}
                      ripple="light"
                      onClick={clickSubmitChangePassword}
                    >
                      <Icon name="save" size="sm" /> บันทึก
                    </Button>
                  </div>
                </div>
              </TabPane>
            </div>
          </TabContent>
        </Tab>
      </div>
    </section>
  );
}
