import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import H6 from "@material-tailwind/react/Heading5";
import InputIcon from "@material-tailwind/react/InputIcon";
import Button from "@material-tailwind/react/Button";
import Image from "@material-tailwind/react/Image";
import Page from "components/login/Page";
import Container from "components/login/Container";
import { UserService, TitleService } from "../providers";
import LogoPicture from "assets/img/background-1920x1280.png";

export default function Login() {
  const [values, setValues] = useState({
    username: "",
    password: "",
    error: "",
  });
  const history = useHistory();

  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false });
    UserService.fetchUserByUsername(values).then((data) => {
      if (data.message) {
        setValues({ ...values, error: data.message });
      } else {
        localStorage.setItem("user", values.username);
        TitleService.fetchEvaluate(values.username).then((res) => {
          if (res.length > 0) history.push("/");
          else history.push("/evaluate");
        });
      }
    });
  };

  return (
    <Page>
      <Container>
        <Card>
          <CardHeader color="lightBlue">
            <H6 color="white" style={{ marginBottom: 0 }}>
              แบบประเมินความรู้
            </H6>
          </CardHeader>

          <CardBody>
            <div className="mb-8 px-4 bg-bb">
              <InputIcon
                type="text"
                color="lightBlue"
                placeholder="ชื่อผู้ใช้งาน"
                iconName="person"
                onChange={handleChange("username")}
              />
            </div>
            <div className="mb-8 px-4">
              <InputIcon
                type="password"
                color="lightBlue"
                placeholder="รหัสผ่าน"
                iconName="lock"
                onChange={handleChange("password")}
              />
            </div>
            {values.error ? (
              <div className="mb-0 px-4">
                <div className="text-red-700">{values.error}</div>
              </div>
            ) : null}
            <div className="mb-0 px-4">
              <Link to="/register">
                <div className="text-blue-700">สมัครสมาชิก</div>
              </Link>
            </div>
          </CardBody>
          <CardFooter>
            <div className="flex justify-center bg-bb">
              <Button
                color="green"
                buttonType="filled"
                size="regular"
                rounded={false}
                block={false}
                iconOnly={false}
                ripple="light"
                onClick={clickSubmit}
              >
                เข้าใช้งานระบบ
              </Button>
            </div>
          </CardFooter>
        </Card>
        <div className="fixed w-40 mt-20 left-1/2 -translate-x-1/2">
          <Image src={LogoPicture}/>
        </div>
      </Container>
    </Page>
  );
}
