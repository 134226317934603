import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import CardFooter from "@material-tailwind/react/CardFooter";
import H5 from "@material-tailwind/react/Heading5";
import InputIcon from "@material-tailwind/react/InputIcon";
import Button from "@material-tailwind/react/Button";
import Image from "@material-tailwind/react/Image";
import Page from "components/login/Page";
import Container from "components/login/Container";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { ProvinceService, UserService } from "../providers";
import { isEmpty } from "../utils/utilities";
import LogoPicture from "assets/img/background-1920x1280.png";

export default function Register() {
  const [provinces, setProvince] = useState([]);
  const [alert, setAlert] = useState({ alert: null });
  const [values, setValues] = useState({
    username: "",
    password: "",
    firstname: "",
    lastname: "",
    email: "",
    provinceid: 0,
    error: "",
    success: false,
  });
  const history = useHistory();

  useEffect(() => {
    ProvinceService.fetchProvince().then((res) => {
      setProvince(res);
    });
  }, []);

  const handleChange = (name) => (event) => {
    if (name === "provinceid")
      setValues({ ...values, error: false, [name]: event.value });
    else setValues({ ...values, error: false, [name]: event.target.value });
  };

  const handleError = (name, fieldname) => {
    if (
      (name === "username" && isEmpty(values.username)) ||
      (name === "password" && isEmpty(values.password)) ||
      (name === "firstname" && isEmpty(values.firstname)) ||
      (name === "lastname" && isEmpty(values.lastname))
    )
      return `จำเป็นต้องกรอก ${fieldname}`;
  };

  const clickSubmit = (event) => {
    if (
      !isEmpty(values.username) &&
      !isEmpty(values.password) &&
      !isEmpty(values.firstname) &&
      !isEmpty(values.lastname)
    ) {
      event.preventDefault();
      setValues({ ...values, error: false });
      UserService.insertUser(values).then((data) => {
        if (data.error) {
          setValues({ ...values, error: data.error });
          onError(data.error);
        } else if (data.id === undefined) {
          let err = `Username : ${values.username} มีการใช้งานในระบบแล้ว`;
          setValues({ ...values, error: err });
          onError(err);
        } else {
          onSuccess();
        }
      });
    }
  };

  const onSuccess = () => {
    setAlert({
      alert: (
        <SweetAlert
          success
          title="ดําเนินการเรียบร้อยแล้ว"
          onConfirm={() => hideAlert("success")}
        >
          ข้อมูลของคุณได้ทำการบันทึกเรียบร้อยแล้ว
        </SweetAlert>
      ),
    });
  };

  const onError = (err) => {
    setAlert({
      alert: (
        <SweetAlert title="" onConfirm={() => hideAlert("error")}>
          {err}
        </SweetAlert>
      ),
    });
  };

  const hideAlert = (str) => {
    setAlert({ alert: null });
    if (str === "success") history.push("/login");
  };

  return (
    <div>
      {alert.alert}
      <Page>
        <Container>
          <Card>
            <CardHeader color="lightBlue">
              <H5 color="white" style={{ marginBottom: 0 }}>
                สมัครสมาชิก
              </H5>
            </CardHeader>

            <CardBody>
              <div className="mb-4 px-4">
                <InputIcon
                  type="text"
                  color="lightBlue"
                  placeholder="ชื่อผู้ใช้งาน"
                  iconName="account_circle"
                  error={handleError("username", "ชื่อผู้ใช้งาน")}
                  onChange={handleChange("username")}
                />
              </div>
              <div className="mb-4 px-4">
                <InputIcon
                  type="password"
                  color="lightBlue"
                  placeholder="รหัสผ่าน"
                  iconName="lock"
                  error={handleError("password", "รหัสผ่าน")}
                  onChange={handleChange("password")}
                />
              </div>
              <div className="mb-4 px-4">
                <InputIcon
                  type="text"
                  color="lightBlue"
                  placeholder="ชื่อ"
                  iconName="account_circle"
                  error={handleError("firstname", "ชื่อ")}
                  onChange={handleChange("firstname")}
                />
              </div>
              <div className="mb-4 px-4">
                <InputIcon
                  type="text"
                  color="lightBlue"
                  placeholder="นามสกุล"
                  iconName="account_circle"
                  error={handleError("lastname", "นามสกุล")}
                  onChange={handleChange("lastname")}
                />
              </div>
              <div className="mb-4 px-4">
                <InputIcon
                  type="email"
                  color="lightBlue"
                  placeholder="อีเมลล์"
                  iconName="email"
                  onChange={handleChange("email")}
                />
              </div>
              <div className="mb-4 px-4">
                <Select
                  options={provinces}
                  placeholder="-- กรุณาเลือกจังหวัด --"
                  onChange={handleChange("provinceid")}
                />
              </div>
            </CardBody>
            <CardFooter>
              <div className="flex justify-center">
                <Button
                  color="green"
                  buttonType="filled"
                  size="regular"
                  rounded={false}
                  block={false}
                  iconOnly={false}
                  ripple="light"
                  onClick={clickSubmit}
                >
                  สมัครสมาชิก
                </Button>
              </div>
            </CardFooter>
          </Card>
          <div className="fixed w-40 mt-10 left-1/2 -translate-x-1/2">
            <Image src={LogoPicture} />
          </div>
        </Container>
      </Page>
    </div>
  );
}
