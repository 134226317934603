import HttpRequest from '../helpers/HttpRequest';

export default class TitleProvider extends HttpRequest {
  fetchTitle (username, answerno) {
    return this.fetch(`title?username=${username}&answerno=${answerno}`);
  };

  insertAnswer (params) {
    return this.create('title/answer', params)
  };

  fetchEvaluate (username) {
    return this.fetch(`title/listEvaluates?username=${username}`);
  };
};
