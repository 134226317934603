import axios from "axios";
import { BASE_URL } from "./config";

const axiosInstance = axios.create({
    baseURL: BASE_URL
})

axiosInstance.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }

    return Promise.reject(error)
  }
)

class HttpRequest {
  constructor() {
    this.axiosInstance = axiosInstance;
  }

  setHeader(header) {
    this.axiosInstance.defaults.headers.common[header.key] = header.value;
  }

  fetch(methodName, data) {
    return this.axiosInstance.get(methodName, data);
  }

  create(methodName, data) {
    return this.axiosInstance.post(methodName, data);
  }

  update(methodName, data) {
    return this.axiosInstance.put(methodName, data);
  }

  edit(methodName, data) {
    return this.axiosInstance.patch(methodName, data);
  }

  delete(methodName, data) {
    return this.axiosInstance.delete(methodName, {data: data});
  }

  static request(type, url, data) {
    let promise = null;
    switch (type) {
      case "GET":
        promise = axios.get(url, data);
        break;
      case "POST":
        promise = axios.post(url, data);
        break;
      case "PUT":
        promise = axios.put(url, data);
        break;
      case "PATCH":
        promise = axios.patch(url, data);
        break;
      case "DELETE":
        promise = axios.delete(url, data);
        break;
      default:
        promise = axios.get(url, data);
    }

    return promise;
  }
}

export default HttpRequest;
