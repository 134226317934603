import HttpRequest from '../helpers/HttpRequest';

export default class UserProvider extends HttpRequest {
  fetchUserByID (id) {
    return this.fetch(`user/${id}`)
  };

  fetchUserByUsername (params) {
    return this.create(`user/login`, params)
  };

  insertUser (params) {
    return this.create('user', params)
  };

  updateUser (params) {
    return this.update('user', params)
  };

  changePassword (params) {
    return this.update('user/changepassword', params)
  };
};
