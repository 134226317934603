import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-tailwind/react/Button";
import Card from "@material-tailwind/react/Card";
import CardHeader from "@material-tailwind/react/CardHeader";
import CardBody from "@material-tailwind/react/CardBody";
import Icon from "@material-tailwind/react/Icon";
import moment from "moment";
import { TitleService } from "../../providers";

export default function Content() {
  const [evaluate, setEvaluates] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const username = localStorage.getItem("user");
    TitleService.fetchEvaluate(username).then((res) => {
      setEvaluates(res);
    });
  }, []);

  const clickCreateEvaluate = () => {
    localStorage.removeItem("answerno");
    history.push("/evaluate");
  };

  const clickDetailEvaluate = (answerno) => {
    localStorage.setItem("answerno", answerno);
    history.push("/evaluate");
  };

  return (
    <section className="relative py-16 pb-6 bg-gray-100">
      <div className="container max-w-7xl mx-auto px-4 pt-10">
        <Card>
          <CardHeader color="purple" contentPosition="none">
            <div className="w-full flex items-center justify-between">
              <h2 className="text-white text-2xl">รายการแบบประเมินของฉัน</h2>
              <Button
                color="green"
                buttonType="filled"
                size="regular"
                rounded={false}
                block={false}
                iconOnly={false}
                ripple="light"
                onClick={clickCreateEvaluate}
              >
                <Icon name="add" size="sm" /> เพิ่มแบบการประเมิน
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <div className="overflow-x-auto">
              <table className="items-center w-full bg-transparent border-collapse re">
                <thead>
                  <tr>
                    <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                      ลำดับที่
                    </th>
                    <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                      วันที่ทำแบบประเมิน
                    </th>
                    <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                      ผลคะแนน
                    </th>
                    <th className="px-2 text-purple-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-center">
                      รายละเอียด
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {evaluate.map((item, index) => (
                    <tr key={index}>
                      <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                        {index + 1}.
                      </th>
                      <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                        {moment(item.createdate).format("DD-MM-YYYY HH:mm:ss")}
                      </th>
                      <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                        {item.totalscore}
                      </th>
                      <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-center">
                        <div className="flex justify-center">
                          <Button
                            color="lightBlue"
                            buttonType="link"
                            size="regular"
                            rounded={false}
                            block={false}
                            iconOnly={false}
                            ripple="dark"
                            onClick={() => clickDetailEvaluate(item.answerno)}
                          >
                            รายละเอียด
                          </Button>
                        </div>
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </div>
    </section>
  );
}
