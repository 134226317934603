import React from "react";
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import Evaluate from 'pages/Evaluate';
import Dashboard from 'pages/Dashboard';
import Login from 'pages/Login';
import Register from 'pages/Register';
import Profile from 'pages/Profile';
import { isEmpty } from '../src/utils/utilities';

// Font Awesome Style Sheet
import '@fortawesome/fontawesome-free/css/all.min.css';

// Tailwind CSS Style Sheet
import 'assets/styles/tailwind.css';

function App() {
    const history = useHistory();

    return (
        <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/evaluate" component={Evaluate} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            {
                isEmpty(localStorage.getItem('user')) ? history.push('/login') : <Redirect from="*" to="/" />
            }
        </Switch>
    );
}

export default App;
