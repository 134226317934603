import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Navbar from "@material-tailwind/react/Navbar";
import NavbarContainer from "@material-tailwind/react/NavbarContainer";
import NavbarWrapper from "@material-tailwind/react/NavbarWrapper";
import NavbarBrand from "@material-tailwind/react/NavbarBrand";
import NavbarToggler from "@material-tailwind/react/NavbarToggler";
import NavbarCollapse from "@material-tailwind/react/NavbarCollapse";
import Nav from "@material-tailwind/react/Nav";
import NavItem from "@material-tailwind/react/NavItem";
import Icon from "@material-tailwind/react/Icon";

export default function DefaultNavbar() {
  const [openNavbar, setOpenNavbar] = useState(false);
  const history = useHistory();

  const logout = () => {
    localStorage.clear();
    history.push("/login");
  };

  const toDashboard = () => {
    localStorage.removeItem("answerno");
    history.push("/");
  };

  const profile = () => {
    history.push("/profile");
  }

  return (
    <Navbar color="lightBlue" navbar>
      <NavbarContainer>
        <NavbarWrapper>
          <NavbarBrand>
            <div className="text-xl">แบบประเมินความรู้</div>
          </NavbarBrand>
          <NavbarToggler
            color="white"
            onClick={() => setOpenNavbar(!openNavbar)}
            ripple="light"
          />
        </NavbarWrapper>

        <NavbarCollapse open={openNavbar}>
          <Nav leftSide>
            <div className="cursorClick">
              <NavItem ripple="light" onClick={toDashboard}>
                <Icon name="home" size="xl" />
                หน้าหลัก
              </NavItem>
            </div>
          </Nav>
          <Nav>
            <div className="cursorClick">
              <NavItem ripple="light" onClick={profile}>
                <Icon name="account_circle" size="xl" />
                โปรไฟล์
              </NavItem>
            </div>
            <div className="cursorClick">
              <NavItem ripple="light" onClick={logout}>
                <Icon name="logout" size="xl" />
                ออกจากระบบ
              </NavItem>
            </div>
          </Nav>
        </NavbarCollapse>
      </NavbarContainer>
    </Navbar>
  );
}
